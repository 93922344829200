import React, { useEffect, useRef, useState, useTransition } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

import TableBlock from "./TableBlock/TableBlock";
import UsersSubheader from "./Subheaders/UsersSubheader";
import TeamsSubheader from "./Subheaders/TeamsSubheader";

import cn from 'classnames';
import styles from "./styles.module.scss";
import UserApi from "../../utils/api/UserApi";
import { ALL_STUDENTS_TABLE_HEADERS, BRANCH_ROLE_STUDENT, DEFAULT_DATA_LIMIT, PART_OF_MENTOR_NAME, ROLE_ID_ADMIN, ROLE_ID_GUEST, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, TEAMS_HEADERS, USER_STATUS_ACTIVE, USER_STATUS_BANNED, BIGGER_DATA_LIMIT } from "../../constants/user";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import TeamApi from "../../utils/api/TeamApi";
import { useSelector } from "react-redux";
import useUserRole from "../../hooks/useUserRole";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import debounce from 'lodash/debounce';
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import { transformRoleText } from "../../utils/rolesHelper";
import { TEAM_EXTRA_GROUP, TEAM_EXTRA_GROUP_TABLE } from "../../utils/api/serialization_groups";
import useGetTeams from "../../hooks/api/useGetTeamsOfCompany";
import { sortRoles } from "../../utils/usersHelper";
import useFindTranslations from "../../hooks/useFindTranlsations";
import useHandleModal from "../../hooks/dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";

const UsersPage = () => {
  const [activeTab, setActiveTab] = useState("students");

  const previousUsersRequest = useRef(null);
  const confirmChangeStatusModal = useHandleModal();

  const [searchedValue, setSearchedValue] = useState('');
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isUsersLoading, setIsUsersLoading] = useState(false);

  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectMode, setSelectMode] = useState('');
  const [progress, setProgress] = useState();
  const [clickedStatusBtn, setClickedStatusBtn] = useState(false);
  const [isChangeStatusesLoading, setIsChangeStatusesLoading] = useState(false);
  const isCancelRequestsRef = useRef(null);

   const openConfirmChangeStatusesModal = (whatToDo) => {
      setClickedStatusBtn(whatToDo);
      isCancelRequestsRef.current = null;
      confirmChangeStatusModal.open();
   }

   const onCancelChangeSatatus = () => {
      isCancelRequestsRef.current = true;
      confirmChangeStatusModal.close();
    }

   const changeSelectAllMode = (mode) => {
      setSelectMode(mode);
   }

  const companyId = useSelector(state => state.sidebar.companyId);
  const currentCompanyName = useSelector(state => state.sidebar.companyName);

  const userInfo = useSelector(state => state?.user?.info);
  const { userRoleName } = useUserRole(companyId)

  const t = useFindTranslations();

   const mapTeams = (team => ({
      id: team?.id,
      department: team?.department || '-',
      position: team?.position ? team?.position : '-',
      team: team?.name,
      amount: team?.users?.length,
   }))

   const addUserToList = (newUser) => {
      const newUsersList = [newUser, ...userList];
      setTotalUsers(totalUsers + 1);
      setUserList(newUsersList);
    };

   const addUsersToList = (newUsers) => {
      const newUsersList = [...newUsers, ...userList];
      setTotalUsers(totalUsers + newUsers?.length);
      setUserList(newUsersList);
    };

    const fetchUsers = async (page, limit = null) => {
      setIsUsersLoading(true);

      const controller = new AbortController();
      previousUsersRequest.current = controller;
      const signal = controller.signal;
      
      const isStudents = activeTab === 'students';
      const isSpecialRoles = activeTab === 'roles';
      const isBlocked = activeTab === 'blocked';

      const customLimit = limit ? limit : (selectAll ? BIGGER_DATA_LIMIT : DEFAULT_DATA_LIMIT);

      const res = await new UserApi().getUsersByRoles(page ? page : currentPage, customLimit, companyId, isStudents, isSpecialRoles, isBlocked, searchedValue, signal);

      if(res?.success?.data) {

         if(selectAll) {
            setSelectedUserList(res?.success?.data)
         }
         
         if(page) {
            setUserList(res?.success?.data);
         } else {
            setUserList((prev)=> [...prev, ...res?.success?.data]);
         }

         setTotalUsers(res?.success?.totalCount)
      }

      if(res?.success?.data?.length === 0) {
         setCurrentPage(1);
      }

      if(res?.error?.message && page) {
         setUserList([]);
      }

      setIsUsersLoading(false);
   }

   const lastUserRef = useIntersectionObserver(
      () => {
        if (!isUsersLoading && userList?.length < totalUsers) {
          if(userList?.length === 0) {
            setCurrentPage(1);
          } else {
            setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [isUsersLoading, totalUsers]
    );

   useEffect(()=> {
      if(currentPage > 1 && userList?.length <= totalUsers && !isUsersLoading) {
         fetchUsers();
      }
   }, [currentPage])

   const debouncedFetchUsers = debounce(()=> fetchUsers(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
      if(companyId && activeTab && activeTab !== 'teams') {
         setCurrentPage(1);
         debouncedFetchUsers();
      }

      return () => debouncedFetchUsers.cancel();
   }, [searchedValue, activeTab, companyId])

   useEffect(() => {
      if (selectAll && userList?.length !== totalUsers && userList?.length < BIGGER_DATA_LIMIT) {
         setCurrentPage(1);
         debouncedFetchUsers();
      }

      return () => debouncedFetchUsers.cancel();
   }, [selectAll]);

   const refetchUsers = () => {
      debouncedFetchUsers();
      setCurrentPage(1);
   };

   useEffect(()=> {
      if(selectMode === 'select_all') {
         setSelectAll(true);
         setSelectedUserList(userList);
      } else {
         setSelectAll(false);
      }
    }, [selectMode])

    const toggleSelectAllUsersInTable = () => {
      setSelectAll(!selectAll);
      
      if(!selectAll) {
         setSelectedUserList(userList);
      } else {
         setSelectedUserList([]);
      }
    }

   useEffect(()=> {
      setUserList([]);
      if(activeTab !== 'teams') setIsUsersLoading(true);
      if(activeTab === 'teams') setIsUsersLoading(false);
      if(previousUsersRequest?.current) {
         previousUsersRequest.current.abort();
      }  
      setSearchedValue('');
      setSelectedUserList([]);
      setSelectMode('')
   }, [activeTab])


   const teamManager = useGetTeams({ companyId, userRoleName, serializationGroup: TEAM_EXTRA_GROUP_TABLE });

   const lastTeamRef = useIntersectionObserver(
      () => {
        if (!teamManager.isTeamsLoading && teamManager.teamsList?.length < teamManager.totalTeams) {
          if(teamManager.teamsList?.length === 0) {
            teamManager.setCurrentPage(1);
          } else {
            teamManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [teamManager.isTeamsLoading, teamManager.totalTeams]
    );

   useEffect(()=> {
      if(userRoleName?.includes(PART_OF_MENTOR_NAME)){
         setIsUsersLoading(false);
      } 
   }, [userRoleName])


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

 useEffect(() => {
   if(userRoleName?.includes(PART_OF_MENTOR_NAME)) {
      setActiveTab('teams')
   }
 }, [userRoleName]);

 const mappedUserList = userList?.map(user => {
   const sortedRoles = user?.userBranchRoles
     ?.filter(role => role?.branch?.company?.id === +companyId)
     ?.sort(sortRoles);
 
   const studentRole = user?.userBranchRoles
     ?.find(role => role?.role?.id === ROLE_ID_STUDENT && role?.branch?.company?.id === +companyId)?.role;
 
   const role = activeTab === "students"
     ? studentRole
       ? transformRoleText(studentRole.name, currentCompanyName)
       : "-"
     : transformRoleText(
         (sortedRoles?.[0]?.role?.name) || "-", 
         currentCompanyName
       );

   const roleObj = activeTab === "students"
       ? user?.userBranchRoles
           ?.find(role => role?.role?.id === ROLE_ID_STUDENT && role?.branch?.company?.id === +companyId) || null
       : sortedRoles?.[0] || null;

   const isStudentRoleIsTheOnlyRoleInCompany = (studentRole && sortedRoles?.length === 1 && sortedRoles[0]?.role?.id === ROLE_ID_STUDENT) || (!sortedRoles?.length && activeTab === "students");
       
   return {
     id: user?.id,
     name: user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : '-',
     email: user?.email || '-',
     department: user?.department || '-',
     position: user?.position || "-",
     role,
     studentRoleIfFound: studentRole || null,
     roleObj,
     isStudentRoleIsTheOnlyRoleInCompany: isStudentRoleIsTheOnlyRoleInCompany ? 'yes' : 'no',
     was: user?.lastActivity ? moment(user?.lastActivity).format("MMM DD, YYYY h:mm a") : '-',
     status: user.status,
   };
 });

const mappedTeams = teamManager?.teamsList?.map(mapTeams)

 const generateCsvData = () => {
   if (activeTab === 'students' || activeTab === 'roles' || activeTab === 'blocked') {
     return [
       ALL_STUDENTS_TABLE_HEADERS,
       ...mappedUserList?.map(({ name, email, department, position, role, was }) => [
         name, email, department, position, role, was,
       ])
     ];
   } 
   return []
 };

 const generateCsvDataTeams = () => {
   return [
      TEAMS_HEADERS,
      ...mappedTeams?.map(({ department, position, team, amount }) => [
         team, department, position, amount
       ])
   ];
 };

 const chunkArray = (array, chunkSize) => {
   const chunks = [];
   for (let i = 0; i < array.length; i += chunkSize) {
     chunks.push(array.slice(i, i + chunkSize));
   }
   return chunks;
 };

 const changeUserStatusInChunks = async (users, status = 1) => {
   const userChunks = chunkArray(users, 2);
   const allAddUserPromises = [];

   const isToDelete = clickedStatusBtn === 'Delete';
  
   let i = -1;

   for (const chunk of userChunks) {
      i++;

      if (isCancelRequestsRef.current) {
         break; 
     }

     const data = isToDelete ? {
         id: chunk.map(user => user?.id)
      } : {
       user_ids: chunk.map(user => user?.id),
       new_status: status,
     };

     try {
       const res = isToDelete 
               ? await new UserApi().deleteManyUsers(data) 
               : await new UserApi().changeMassStatuses(data);

       if (res?.success?.message) { 
         toast(<ToastMsg text={res?.success?.message} />);
       }
       
       const newProgress = ((i + 1) / userChunks?.length) * 100;
       setProgress(newProgress?.toFixed());
       
       allAddUserPromises.push(res);
     } catch (error) {
       console.error(`Error adding users in chunk: ${error}`);
     }
   }
 
   return allAddUserPromises;
 };

 const changeMassStatuses = async()=> {
   setIsChangeStatusesLoading(true);

   const status = clickedStatusBtn === "Unblock" 
                       ? USER_STATUS_ACTIVE 
                       : USER_STATUS_BANNED;

   if(selectedUserList.length) {
      const res = await changeUserStatusInChunks(selectedUserList, status);

         if (res?.error?.message) {
            setIsUsersLoading(false);
         } 
      }

      confirmChangeStatusModal.close();
      setSelectedUserList([]);
      setProgress(0);
      setSelectMode('');
      setIsChangeStatusesLoading(false);
      refetchUsers();
 }

 const statusMessages = {
   Unblock: "You can block them again",
   Block: "You can unblock them in the 'Blocked' tab",
   Delete: "Users will have to be added again"
 };
 
 const selectManySubtitle = t?.returnTranslation(statusMessages[clickedStatusBtn] || "");

  return (
    <MainLayout darkBg>
      <main className={styles.users_page}>
         <div className={styles.loader_wrapper}>
            {isUsersLoading && <Loader size={"small"} />}
         </div>
        <div className={styles.tab_navigation}>
         {!userRoleName?.includes(PART_OF_MENTOR_NAME) && 
            <>
               <button
                  className={cn(styles.tab, activeTab === "students" && styles.activeTab)}
                  onClick={() => handleTabChange("students")}
               >
                  {t?.students}
               </button>
               <button
                  className={cn(styles.tab, activeTab === "roles" && styles.activeTab)}
                  onClick={() => handleTabChange("roles")}
               >
                  {t?.roles}
               </button>
               <button
                  className={cn(styles.tab, activeTab === "teams" && styles.activeTab )}
                  onClick={() => handleTabChange("teams")}
               >
                  {t?.teams}
               </button>
               <button
                  className={cn(styles.tab, activeTab === "blocked" && styles.activeTab )}
                  onClick={() => handleTabChange("blocked")}
               >
                  {t?.blocked}
               </button>
            </>
         }

         {userRoleName?.includes(PART_OF_MENTOR_NAME) && 
            <button
               className={cn(styles.tab, activeTab === "teams" && styles.activeTab )}
               onClick={() => handleTabChange("teams")}
            >
               {t?.teams}
            </button>
         }
         
        </div>
      
        {activeTab === "students" && 
         <>
            <UsersSubheader
               key={'students'}
               setSearchedValue={setSearchedValue}
               searchedValue={searchedValue}
               refetchData={debouncedFetchUsers}
               addUserToList={addUsersToList}
               csvData={generateCsvData()}
               fetchUsers={fetchUsers}
               isDataLoading={isUsersLoading}
               t={t}
               selectMode={selectMode}
               selectModeBtnText={'Block'}
               openConfirmChangeStatusesModal={openConfirmChangeStatusesModal}
               changeSelectAllMode={changeSelectAllMode}
               selectedUsers={selectedUserList}
               setSelectedUsers={setSelectedUserList}
            />

            <TableBlock 
               key={'users_table'}
               tableFor={'students'}
               data={mappedUserList} 
               headers={selectMode ? ['checkbox', ...ALL_STUDENTS_TABLE_HEADERS] : ALL_STUDENTS_TABLE_HEADERS}
               refetchUsers={refetchUsers}
               loading={isUsersLoading}
               lastUserRef={lastUserRef}
               setSelectedUsers={setSelectedUserList}
               selectedUsers={selectedUserList}
               toggleSelectAllUsersInTable={toggleSelectAllUsersInTable}
               t={t}
               selectMode={selectMode}
            />
         </>
        }

        {activeTab === "blocked" &&
         <>
               <UsersSubheader
                  isBlocked
                  key={'students_blocked'}
                  setSearchedValue={setSearchedValue}
                  searchedValue={searchedValue}
                  refetchData={debouncedFetchUsers}
                  csvData={generateCsvData()}
                  fetchUsers={fetchUsers}
                  isDataLoading={isUsersLoading}
                  t={t}
                  selectMode={selectMode}
                  selectModeBtnText={'Unblock'}
                  openConfirmChangeStatusesModal={openConfirmChangeStatusesModal}
                  changeSelectAllMode={changeSelectAllMode}
                  selectedUsers={selectedUserList}
                  setSelectedUsers={setSelectedUserList}
               />
               <TableBlock 
                  key={'blocked_users_table'}
                  tableFor="blocked"
                  data={mappedUserList} 
                  headers={selectMode ? ['checkbox', ...ALL_STUDENTS_TABLE_HEADERS] : ALL_STUDENTS_TABLE_HEADERS}
                  refetchUsers={refetchUsers}
                  loading={isUsersLoading}
                  lastUserRef={lastUserRef}
                  setSelectedUsers={setSelectedUserList}
                  selectedUsers={selectedUserList}
                  toggleSelectAllUsersInTable={toggleSelectAllUsersInTable}
                  t={t}
                  selectMode={selectMode}
               />
         </>   
         }
         {activeTab === "roles" && 
            <>
               <UsersSubheader
                  key={'roles'}
                  isAdmins
                  setSearchedValue={setSearchedValue}
                  searchedValue={searchedValue}
                  refetchData={debouncedFetchUsers}
                  addUserToList={addUserToList}
                  csvData={generateCsvData()}
                  fetchUsers={fetchUsers}
                  isDataLoading={isUsersLoading}
                  t={t}
                  selectMode={selectMode}
                  selectModeBtnText={'Block'}
                  openConfirmChangeStatusesModal={openConfirmChangeStatusesModal}
                  changeSelectAllMode={changeSelectAllMode}
                  selectedUsers={selectedUserList}
                  setSelectedUsers={setSelectedUserList}
               />
               <TableBlock 
                  key={'users_table'}
                  tableFor={"roles"}
                  data={mappedUserList} 
                  headers={selectMode ? ['checkbox', ...ALL_STUDENTS_TABLE_HEADERS] : ALL_STUDENTS_TABLE_HEADERS}
                  refetchUsers={refetchUsers}
                  loading={isUsersLoading}
                  lastUserRef={lastUserRef}
                  setSelectedUsers={setSelectedUserList}
                  selectedUsers={selectedUserList}
                  toggleSelectAllUsersInTable={toggleSelectAllUsersInTable}
                  t={t}
                  selectMode={selectMode}
               />
            </>
         }
        {activeTab === "teams" && 
         <>
            <TeamsSubheader
               setSearchedValue={teamManager.setSearchedValue}
               searchedValue={teamManager.searchedValue}
               activeTeamsFilter={teamManager.activeTeamsFilter}
               setActiveTeamsFilter={teamManager.setActiveTeamsFilter}
               refetchTeams={teamManager?.refetchTeams}
               fetchTeams={teamManager?.fetchTeams}
               isDataLoading={teamManager?.isTeamsLoading}
               csvData={generateCsvDataTeams()}
               t={t}
            />
            <TableBlock 
               key={'teams_table'}
               data={mappedTeams} 
               headers={TEAMS_HEADERS}
               tableFor={"teams"}
               searchedValue={searchedValue} 
               loading={teamManager?.isTeamsLoading}
               lastUserRef={lastTeamRef}
               t={t}
            />
         </>
        }
            <ConfirmModal
               isRemove={clickedStatusBtn === "Delete"}
               confirmButtonText={`${progress ? `${t?.returnTranslation("Loading")}... ${progress}%` : `${t?.returnTranslation('Confirm')}`}`}
               onConfirm={changeMassStatuses}
               isOpen={confirmChangeStatusModal.isActive}
               isLoading={isChangeStatusesLoading}
               onClose={confirmChangeStatusModal.close}
               title={t?.returnTranslation("Are you sure?")}
               subtitle={selectManySubtitle}
               progressPercentage={progress}
               onCancel={onCancelChangeSatatus}
            />
      </main>
    </MainLayout>
  );
};

export default UsersPage;
