export const sortFiltersData = [
   { name: "First new", label: "desc" },
   { name: "Old first", label: "asc" },
];

export const rolesFiltersData = [
   { name: "Admin" },
   { name: "Mentor" },
   { name: "Curator" },
   { name: "Student" },
];
export const checkBoxFiltersData = [
   { name: "Select", label: 'select' },
   { name: "Delete all", label: 'delete_all' },
];

export const optionsFiltersData = [
   { name: "All", label: null },
   { name: "Only read", label: 'true' },
   { name: "Only unread", label: 'false'},
];

export const extractScoreAndLink = (message) => {
   const scoreMatch = message?.match(/(\d+)%/);
   const linkMatch = message?.match(/\bhttps?:\/\/\S+/i);

   const score = scoreMatch ? scoreMatch?.[1] : null;
   let link = linkMatch ? linkMatch?.[0] : null;

   // Remove the link from the original message
   const cleanedMessage = link
      ? message?.replace(link, '')?.replace(/Score: \d+%/, '')?.trim()
      : message;

   // Replace the domain in the link
   if (link && link.includes('https://skillhubr.com')) {
      link = link.replace('https://skillhubr.com', process.env.REACT_APP_API || window.location.origin);
   }

   return { score, link, cleanedMessage };
};

export const NOTIFICATIONS_COLORS = [
   { title: "Mentor", color: '#67D37F' },
   { title: "Student", color: '#B079F0' },
   { title: "Curator", color: '#8AADEF' },
   { title: "Admin", color: '#EDBA5C' },
 ];

export const ROLES_COLORS = [
   { title: "Mentor", color: '#67D37F' },
   { title: "Student", color: '#B079F0' },
   { title: "As Student", color: '#2c9c00' },
   { title: "Curator", color: '#8AADEF' },
   { title: "Admin", color: '#EDBA5C' },
   { title: "Super admin", color: '#ca3b11'},
 ];
 
export const defineNotificationColor = (roleName) => {
   const roleColors = {
     mentor: '#67D37F',
     student: '#B079F0',
     curator: '#8AADEF',
     admin: '#EDBA5C',
   };
 
   const lowerCaseRoleName = roleName ? roleName.toLowerCase() : '';
 
   if (lowerCaseRoleName) {
     if (lowerCaseRoleName.includes('mentor')) {
       return roleColors['mentor'] || '#8AADEF';
     } else if (lowerCaseRoleName.includes('student')) {
       return roleColors['student'] || '#8AADEF';
     } else if (lowerCaseRoleName.includes('curator')) {
       return roleColors['curator'] || '#8AADEF';
     } else if (lowerCaseRoleName.includes('admin')) {
       return roleColors['admin'] || '#8AADEF';
     }
   }
 
   return '#8AADEF';
 };

