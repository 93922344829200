import React from "react";
import { Tooltip } from "react-tooltip";

import styles from "./styles.module.scss";
import cn from "classnames";

const CustomStaticTooltip = ({
   text,
   hint,
   maxWidth,
   id,
   extraMarginLeft,
   icon,
   position,
}) => {
   return (
      <div className={styles.wrapper}>
         <span className={styles.flex} data-tooltip-id={id} data-tooltip-content={hint}>
            {icon && 
               <img src={icon} alt="" />
            }  
           {text}
         </span>

         <Tooltip 
            className={styles.tooltip}
            id={id}   
            style={{ maxWidth: maxWidth}}
            position={position}
         />
      </div>
   );
};

export default CustomStaticTooltip;
