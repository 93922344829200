import React, { useState, useEffect } from "react";
import { debounce } from 'lodash';
import { FixedSizeList as List } from "react-window";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import UsersBodyItem from "./UsersBodyItem";
import styles from "./styles.module.scss";
import Checkbox from "../../../components/Checkbox/Checkbox";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import SecondLoader from "../../../components/SecondLoader/SecondLoader";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import CourseStatusModal from "../../../components/Modals/CourseStatusModal/CourseStatusModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import UserApi from "../../../utils/api/UserApi";
import {
   USER_STATUS_BANNED,
} from "../../../constants/user";

const UsersTable = ({
   columns,
   data,
   tableFor,
   changeUserStatus,
   loading,
   deleteUserFromTable,
   refetchCourseUsers,
   orderBy,
   setOrderBy,
   order,
   setOrder,
   selectMode,
   onClickRow,
   selectedUserListChangeStatus,
   toggleSelectAllUsersInTable,
   lastUserRef,
}) => {
   const [clickedUserId, setClickedUserId] = useState(null);
   const [selectedRole, setSelectedRole] = useState({});
   const [clickedItem, setClickedItem] = useState(null);
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   
   const t = useFindTranslationsByString();
   const navigate = useNavigate();
   const confirmModal = useHandleModal();
   const handleStatusModal = useHandleModal();

   const onOpenStatusModal = (item) => {
      handleStatusModal.open();
      setClickedItem(item);
   };

   const onSelectRole = (role) => {
      setSelectedRole(role);
   };

   const openConfirmModal = (userId) => {
      setClickedUserId(userId);
      confirmModal.open();
   };

   const onDeleteFromPlatform = async () => {
      setIsChangeLoading(true);

      const res = await new UserApi().updateUser(clickedUserId, {
         new_status: USER_STATUS_BANNED,
      });

      if (res?.success?.message) {
         if (deleteUserFromTable) deleteUserFromTable(clickedUserId);
         changeUserStatus(clickedUserId, USER_STATUS_BANNED);
         refetchCourseUsers();
         toast(<ToastMsg text={res.success.message} />);
      }

      if (res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError />);
      setIsChangeLoading(false);
      confirmModal.close();
   };

   const handleSort = (column) => {
      const columnMappings = {
         team: { orderBy: "team", orderToggle: true },
         progress: { orderBy: "progress", orderToggle: true },
         name: { orderBy: "first_name", orderToggle: true },
         department: { orderBy: "department", orderToggle: true },
         position: { orderBy: "position", orderToggle: true },
         was: { orderBy: "updated_at", orderToggle: true },
         status: { orderBy: "user_course_status", orderToggle: true },
      };

      const columnMapping = columnMappings[column];
      if (columnMapping) {
         const { orderBy, orderToggle } = columnMapping;
         setOrderBy(orderBy || "");
         if (orderToggle) {
            setOrder(order === "asc" ? "desc" : "asc");
         }
      }
   };

   if (data?.length === 0 && !loading) {
      return <EmptyBlock />;
   }

   const Row = ({ index, style }) => {
      const row = data[index];
      
      return (
         <div style={style} className={cn(styles.row, { [styles.row_selected]: selectedUserListChangeStatus.includes(row.id) })}>
            <UsersBodyItem
               tableFor={tableFor}
               row={row}
               columns={columns}
               index={index}
               openConfirmModal={openConfirmModal}
               onOpenStatusModal={onOpenStatusModal}
               lastItemRef={index === data?.length - 1 ? lastUserRef : null}
               selectMode={selectMode}
               onClickRow={onClickRow}
               selectedUserListChangeStatus={selectedUserListChangeStatus}
            />
         </div>
      );
   };

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column) => (
                     <th key={column} onClick={() => handleSort(column)} className={styles.header}>
                        {column === "checkbox" ? (
                           <div onClick={toggleSelectAllUsersInTable} className={styles.checbox}>
                              <Checkbox
                                 isChecked={selectedUserListChangeStatus?.length === data?.length}
                                 isGrey
                              />
                              <span className={styles.num}>
                                 {selectedUserListChangeStatus?.length > 0 ? selectedUserListChangeStatus?.length : ""}
                              </span>
                           </div>
                        ) : (
                           <>
                              <span>{t(column?.replace("_", " "))}</span>
                              <div className={styles.arrow_wrapper}>
                                 <span className={styles.arrow}></span>
                              </div>
                           </>
                        )}
                     </th>
                  ))}
                  <th className={styles.option_th}></th>
               </tr>
            </thead>
         </table>

        {!!data?.length && 
          <List
              height={window.innerHeight * 0.60}
              itemCount={data?.length}
              itemSize={70} 
              width="100%"
           >
              {Row}
           </List>
        }

         {data?.length === 0 && !loading && <EmptyBlock />}

         {loading && (
            <div className="default_loader_wrapper">
               <SecondLoader />
            </div>
         )}

         <ConfirmModal
            confirmButtonText={t("Block user")}
            onConfirm={onDeleteFromPlatform}
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            title={t("You sure you want to block the user?")}
            isLoading={isChangeLoading}
         />

         <CourseStatusModal
            onClose={handleStatusModal.close}
            isOpen={handleStatusModal.isActive}
            item={clickedItem}
            refetchCourseUsers={refetchCourseUsers}
         />
      </div>
   );
};

export default UsersTable;
