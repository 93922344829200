import ModalLayout from "../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { pathList } from "../../routes/path";
import Loader from "../Loader/Loader";
import notificationImg from "../../assets/images/notification_white.svg";
import doneImg from "../../assets/images/arrow-success.svg";
import deleteImg from "../../assets/images/delete.svg";
import emptyNotifications from "../../assets/images/empty_not.svg";
import iconInfo from "../../assets/images/icon_info.svg";
import NotificationModalLayout from "../../layouts/ModalLayout/NotificationModalLayout";
import FiltersBlock from "./FiltersBlock/FiltersBlock";
import { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import NotificationsApi from "../../utils/api/NotificationsApi";
import {
   deleteNotificationById,
   toggleMuted,
} from "../../store/actions/notifications";
import { useDispatch } from "react-redux";

import cn from "classnames";
import {
   NOTIFICATIONS_COLORS,
   defineNotificationColor,
   extractScoreAndLink,
} from "../../constants/notifications";
import { useSelector } from "react-redux";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import useHandleModal from "../../hooks/dom/useHandleModal";
import useUserRole from "../../hooks/useUserRole";
import { DEFAULT_TEXT, returnTranslation } from "../../constants/languages";

const NotificationPanel = ({
   onClose,
   isOpen,
   notifications,
   setNotifications,
   lastElementRef,
   sortFilter,
   dateFilter,
   setSortFilter,
   setDateFilter,
   roleFilter,
   setRoleFilter,
   isLoading,
   notRedNotifications,
   readFilter,
   setReadFilter,
   selectFilter,
   setSelectFilter,
   onSelectNotifications,
   selectedNotifications,
   confirmDeleteModal,
   isDeleteLoading
}) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const muted = useSelector((state) => state?.notifications?.muted);
   const [isMarked, setIsMarked] = useState(false);
   const [showInfo, setShowInfo] = useState(false);

   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const translations = useSelector(state => state.sidebar.translations);
   const { filteredByCompanyRoles: userBranchRoles, userRoleName } = useUserRole(currentCompanyId)

   const dropDownRef = useRef(null);
   const optionRef = useRef(null);
   const closeOptions = () => {
      setShowInfo(false);
   };

   const yourScoreText = returnTranslation(translations, DEFAULT_TEXT.modals?.yourScore);

   useOutsideClick(dropDownRef, closeOptions, optionRef);

   const addScoreElement = (message, score, link) => {
      if (message?.includes("%")) {
         return (
            <Link
               target="_blank"
               rel="noopener noreferrer"
               to={link}
               className={styles.score_wrapper}
            >
               <div className={styles.score_title}>{yourScoreText}</div>
               <div className={styles.score}>{score} / 100</div>
            </Link>
         );
      }
      return null;
   };

   const chunkArray = (array, size) => {
      const chunked = [];
      for (let i = 0; i < array.length; i += size) {
          chunked.push(array.slice(i, i + size));
      }
      return chunked;
  };

   const readOnCloseModal = async () => {
      const loadedNotReadNotifications = notifications?.filter(
         (item) => !item?.isArchived
      );
      if (!notRedNotifications?.length) return;

      const notificationsToArchive = notRedNotifications?.map((item) => ({
         user_notification_id: item?.id,
         archive: true,
      }));

     const chunks = chunkArray(notificationsToArchive, 100);

     for (const chunk of chunks) {
         const dataToSend = {
             user_notification_data: chunk,
         };
 
         const res = await new NotificationsApi().archiveYourNotifications(dataToSend);

         if(res?.success) {
            const notificationsIds = chunk?.map(item => item?.user_notification_id)
            dispatch(deleteNotificationById(notificationsIds));
         }
     }
   };

   const onMarkAll = () => {
      setIsMarked(true);
      readOnCloseModal();
      setNotifications(
         notifications?.map((item) => ({
            ...item,
            isArchived: true,
         }))
      );

      setTimeout(() => {
         setIsMarked(false);
      }, 1000);
   };

   function translateMessage(message) {
      const translationMap = {};
      translations.forEach(t => {
        translationMap[t.originalValue] = t.translatedValue;
      });
    
      const regex = new RegExp(Object.keys(translationMap).join('|'), 'gi');
    
      const translatedMessage = message.replace(regex, (matched) => {
        return translationMap[matched] || matched;
      });
    
      return translatedMessage;
    }

   const notificationsTitle = returnTranslation(translations, DEFAULT_TEXT.notifications_front?.notifications)
   const markAsRead = returnTranslation(translations, DEFAULT_TEXT.notifications_front?.markAllAsRead)
   const mute = returnTranslation(translations, DEFAULT_TEXT.notifications_front?.mute)
   const noNotificationsToShowYet = returnTranslation(translations, DEFAULT_TEXT.modals?.noNotificationsToShowYet)
   const youWillSeeUsefulInformationHereSoon = returnTranslation(translations, DEFAULT_TEXT.modals?.youWillSeeUsefulInformationHereSoon)
   const deleteText = returnTranslation(translations, DEFAULT_TEXT.common?.delete);
   const selectText = returnTranslation(translations, DEFAULT_TEXT.common?.selected);

   return (
      <NotificationModalLayout
         onClose={() => {
            onClose();
            setRoleFilter(null)
            setReadFilter({ name: "All", label: null });
            setDateFilter("");
            setSelectFilter(null)
            if (notRedNotifications?.length) {
               readOnCloseModal();
            }
         }}
         isOpen={isOpen}
         maxHeight={"100vh"}
         maxWidth={"450px"}
         customPadding={"0px"}
         withCross
      >
         <div className={styles.notification_wrapper}>
            <div className={styles.header}>
               <img src={notificationImg} alt="notification image" />
               <span>{notificationsTitle}</span>
               <img
                  ref={optionRef}
                  className={styles.infoIcon}
                  src={iconInfo}
                  alt="Info image"
                  onClick={() => setShowInfo(!showInfo)}
               />

               <div
                  ref={dropDownRef}
                  className={cn(
                     styles.color_hint,
                     showInfo ? styles.show_info : ""
                  )}
               >
                  {NOTIFICATIONS_COLORS.map((item) => (
                     <div key={item.title} className={styles.hint_item}>
                        <div
                           style={{ backgroundColor: item.color }}
                           className={styles.dotM}
                        ></div>
                        <div>{item.title}</div>
                     </div>
                  ))}
               </div>
            </div>

            {isLoading && (
               <div className={styles.loader_wrapper}>
                  <Loader size={"small"} />
               </div>
            )}

            <div className={styles.filters_block}>
               <FiltersBlock
                  sortFilter={sortFilter}
                  setSortFilter={setSortFilter}
                  dateFilter={dateFilter}
                  readFilter={readFilter}
                  setReadFilter={setReadFilter}
                  setDateFilter={setDateFilter}
                  userBranchRoles={userBranchRoles}
                  setSelectFilter={setSelectFilter}
                  selectFilter={selectFilter}
                  roleFilter={roleFilter}
                  setRoleFilter={setRoleFilter}
               />
            </div>

            <div className={styles.notifications_wrapper}>
               {notifications?.map((item, index) => {
                  const createdAt = new Date(item.createdAt);
                  const now = new Date();

                  const isWithin1Minutes =
                     Math.abs(now - createdAt) < 1 * 60 * 1000;

                  const formattedDate = isWithin1Minutes
                     ? "Now"
                     : moment(createdAt).format("DD/MM/YYYY HH:mm");

                  const translatedText = translateMessage(item?.message);

                  const { score, link, cleanedMessage } = extractScoreAndLink(
                     translatedText
                  );


                  if (selectFilter?.label === "select") {
                     return (
                        <div
                           key={index}
                           onClick={(e) => onSelectNotifications(e, item)}
                           className={cn(
                              styles.notification,
                              styles.delete_wrapper,
                              styles.pointer
                           )}
                        >
                           <div className={styles.checkbox}>
                              <input
                                 id={`${item?.id}notif`}
                                 type="checkbox"
                                 onChange={() => {}}
                                 checked={selectedNotifications?.some(
                                    (sItem) => sItem?.id === item?.id
                                 )}
                              />
                              <label
                                 className={styles.checkbox}
                                 htmlFor={`${item?.id}notification`}
                              ></label>
                           </div>
                           <div>
                              {!item?.isArchived && (
                                 <div
                                    style={{
                                       backgroundColor: defineNotificationColor(userRoleName)
                                    }}
                                    className={styles.dot}
                                 ></div>
                              )}
                              <div className={styles.title}>
                                 {translateMessage(item?.title) || notificationsTitle}
                              </div>
                              <div className={styles.text}>
                                 {cleanedMessage}
                              </div>
                              {item?.message?.includes("%") && 
                                 <div className={styles.score_wrapperNoHover}>
                                    <div className={styles.score_title}>
                                       {yourScoreText}
                                    </div>
                                    <div className={styles.score}>
                                       {score} / 100
                                    </div>
                                 </div>
                              }
                             
                              <div className={styles.date}>{formattedDate}</div>
                           </div>
                        </div>
                     );
                  } else {
                     return (
                        <Link
                           ref={
                              notifications?.length === index + 1
                                 ? lastElementRef
                                 : null
                           }
                           key={index}
                           className={cn(
                              styles.notification,
                              link ? styles.pointer : ""
                           )}
                           onClick={(e) => {
                              if (!link) {
                                 e.preventDefault();
                              } else {
                                 readOnCloseModal();
                              }
                           }}
                           to={link}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           {!item?.isArchived && (
                              <div
                                 style={{
                                    backgroundColor: defineNotificationColor(
                                       userBranchRoles?.[0]?.role?.name
                                    ),
                                 }}
                                 className={styles.dot}
                              ></div>
                           )}
                           <div className={styles.title}>
                              {item?.title || "Notification"}
                           </div>
                           <div className={styles.text}>{cleanedMessage}</div>
                           {addScoreElement(item.message, score, link)}
                           <div className={styles.date}>{formattedDate}</div>
                        </Link>
                     );
                  }
               })}
            </div>

            {!notifications.length && !isLoading && (
               <div className={styles.no_data}>
                  <img src={emptyNotifications} alt="" />
                  <div className={styles.no_data_title}>
                     {noNotificationsToShowYet}
                  </div>
                  <div className={styles.grayish}>
                     {youWillSeeUsefulInformationHereSoon}
                  </div>
               </div>
            )}

            {selectFilter?.label === "select" && (
               <div
                  className={cn(
                     styles.notification_footer,
                     styles.notification_footerDelete,
                     isLoading ? styles.loading : ""
                  )}
               >
                 <div>
                   {selectText}: <span>{selectedNotifications?.length}</span>
                 </div>

                  <div className={styles.delete_btn}>
                        <Button
                           title={deleteText}
                           isRed
                           image={deleteImg}
                           onClick={confirmDeleteModal.open}
                           disabled={isDeleteLoading || !selectedNotifications?.length}
                        />
                  </div>
               </div>
            )}

            {selectFilter?.label !== "select" && (
               <div
                  className={cn(
                     styles.notification_footer,
                     isLoading ? styles.loading : ""
                  )}
               >
                  {!isMarked && (
                     <button
                        onClick={onMarkAll}
                        className={styles.mark_all_as_read}
                     >
                        {markAsRead}
                     </button>
                  )}

                  {isMarked && (
                     <div className={styles.marked}>
                        <span>{markAsRead}</span>
                        <img src={doneImg} alt="" />
                     </div>
                  )}

                  <div className={styles.checkbox_row}>
                     <label className={styles.textLabel} htmlFor="mute">
                        {mute}
                     </label>

                     <input
                        className={styles.checkbox_input}
                        type="checkbox"
                        id="mute"
                        checked={muted}
                        onChange={(e) => {
                           dispatch(toggleMuted(e.target.checked));
                        }}
                     />
                     <label
                        className={styles.checkbox_input_label}
                        htmlFor="mute"
                     />
                  </div>
               </div>
            )}
         </div>
      </NotificationModalLayout>
   );
};

export default NotificationPanel;
