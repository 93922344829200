import React, { useEffect, useState, useRef } from "react";
import csvImg from "../../../assets/images/symbols/scv.svg";

import Button from "../../../components/Button/Button";

import styles from "./styles.module.scss";
import { CSVLink } from "react-csv";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";
import {
   TESTS_CHECKED_FILTERS,
   TEST_STATUS_ON_CHECK,
} from "../../../constants/tests";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { ROLE_ID_STUDENT } from "../../../constants/user";
import { useSelector } from "react-redux";
import useUserRole from "../../../hooks/useUserRole";
import FewItemsDropdown from "../../../components/FewItemsDropdown/FewItemsDropdown";
import ExtendedDateDropdown from "../../../components/ExtendedDateDropdown/ExtendedDateDropdown";
import SearchInput from "../../../components/SearchInput/SearchInput";
import useFindTranslations from "../../../hooks/useFindTranlsations";
import { BIGGER_DATA_LIMIT } from '../../../constants/user';

const KnowledgeSubheader = ({
   searchedValue,
   setSearchedValue,
   refetchData,
   csvData,
   activeFilter,
   setActiveFilter,
   checked,
   students,
   studentsSearchedValue,
   setStudentsSearchedValue,
   selectedStudents,
   setSelectedStudents,
   lastStudentRef,
   onConfirmStudentFilter,
   dateFilter,
   setDateFilter,
   isTeamPage,
   frontTimeFilterValue,
   setFrontTimeFilterValue,
   fetchTests,
   isDataLoading,
}) => {
   const csvLinkRef = useRef(null);

   const handleFilterClick = (filter) => {
      setActiveFilter(filter);
   };

   const t = useFindTranslations();

   const handleStudentsFilterClick = (user) => {
      if (selectedStudents?.find((item) => item?.id === user?.id))
         setSelectedStudents(
            selectedStudents?.filter((item) => item?.id !== user?.id)
         );
      else setSelectedStudents([...selectedStudents, user]);
   };

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId);

   return (
      <div className={styles.subheader}>
         <div className={styles.line}></div>
         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>
         </div>
         {checked && (
            <div className={styles.filterBlock}>
               <Dropdown
                  data={TESTS_CHECKED_FILTERS}
                  field={"label"}
                  placeholder={t?.status}
                  value={activeFilter?.label}
                  onChange={(filter) => handleFilterClick(filter)}
                  testStatuses
               />
            </div>
         )}

         <div className={styles.filterBlockDate}>
            <ExtendedDateDropdown 
               dateFilter={dateFilter}
               setDateFilter={setDateFilter}
               frontTimeFilterValue={frontTimeFilterValue}
               setFrontTimeFilterValue={setFrontTimeFilterValue}
            />
         </div>

         {userRoleId && userRoleId !== ROLE_ID_STUDENT && !isTeamPage && (
            <div className={styles.filterBlock}>
               <FewItemsDropdown
                  searchNeeded
                  data={students}
                  field={"label"}
                  placeholder={t?.users}
                  value={activeFilter?.label}
                  onChange={(filter) => handleStudentsFilterClick(filter)}
                  searchValue={studentsSearchedValue}
                  setSearchValue={setStudentsSearchedValue}
                  selectedItems={selectedStudents}
                  setSelectedItems={setSelectedStudents}
                  lastItemRef={lastStudentRef}
                  onConfirm={onConfirmStudentFilter}
               />
            </div>
         )}

         <div className={styles.csv_wrapper}>
            <Button
               withoutBorders
               colorLighter
               isBlack
               onClick={()=> fetchTests(1, BIGGER_DATA_LIMIT).then(()=> csvLinkRef.current.link.click())}
               title={t?.exportToCSV}
               image={csvImg}
               maxWidth={"180px"}
               disabled={isDataLoading}
            />

            <CSVLink ref={csvLinkRef} style={{ textDecoration: "none" }} data={csvData}/>
         </div>
      </div>
   );
};

export default KnowledgeSubheader;
