import React, { useEffect, useState, useRef } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";
import csvImg from "../../../assets/images/symbols/scv.svg";
import plus from "../../../assets/images/symbols/plus.svg";

import Button from "../../../components/Button/Button";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";

import styles from "./styles.module.scss";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { CSVLink } from "react-csv";
import SelectUserModal from "../../../components/Modals/SelectUserModal/SelectUserModal";
import SelectCourseModal from "../../../components/Modals/SelectCourseModal/SelectCourseModal";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import { BIGGER_DATA_LIMIT } from "../../../constants/user";

const Subheader = ({
   searchedValue,
   setSearchedValue,
   studentsAmount,
   requestedAmount,
   refetchData,
   csvData,
   data,
   setSelectedUserList,
   selectedUserList,
   setSelectedCoursesList,
   selectedCoursesList,
   onAddUserToTeam,
   onAddCourseToTeam,
   selectModal,
   isAccess,
   isAddLoading,
   lastStudentRef,
   lastCourseRef,
   allCoursesSearchedValue,
   setAllCoursesSearchedValue,
   selectAll,
   setSelectAll,
   isCoursesLoading,
   studentSearchedValue,
   setStudentSearchedValue,
   fetchDataForCSV,
   setEmails
}) => {

   const csvLinkRef = useRef(null);

   const t = useFindTranslationsByString();

   const handleCSV = () => {
      if(fetchDataForCSV) {
         fetchDataForCSV(1, BIGGER_DATA_LIMIT).then(()=> csvLinkRef.current.link.click())
      } else {
         csvLinkRef.current.link.click();
      }
   }

   return (
      <div className={styles.subheader}>
         <div className={styles.line}></div>
         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <img src={searchImg} alt={""} />
               <input
                  placeholder={t("Search")}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>

            <Button
               title={t("Export to CSV")}
               image={csvImg}
               isBlack
               maxWidth={"180px"}
               onClick={handleCSV}
               disabled={isCoursesLoading}
            />

            <CSVLink ref={csvLinkRef} style={{ textDecoration: "none" }} data={csvData}/>

            <Button
               title={!isAccess ? t("Add users") : t("Add access")}
               image={plus}
               maxWidth={"260px"}
               onClick={selectModal.open}
            />
         </div>

         {!isAccess && 
           <SelectUserModal
               oneStep
               isAddUsersToTeam
               isDataLoading={isAddLoading}
               onClose={selectModal.close}
               isOpen={selectModal.isActive}
               setSelectedUsers={setSelectedUserList}
               selectedUsers={selectedUserList}
               usersList={data}
               onConfirm={onAddUserToTeam}
               lastUserRef={lastStudentRef}
               userSearchValue={studentSearchedValue}
               setUserSearchValue={setStudentSearchedValue}
               hint={t("All courses that you see in ‘access’ tab will be added to users after adding them to the team")}
               isSearchByEmailsNeeded
               setEmails={setEmails}
            />
         }

         { isAccess && 
            <SelectCourseModal
               oneStep
               onClose={selectModal.close}
               isOpen={selectModal.isActive}
               setSelectedCourses={setSelectedCoursesList}
               selectedCourses={selectedCoursesList}
               lastCourseRef={lastCourseRef}
               isDataLoading={isAddLoading}
               coursesList={data}
               searchedValue={allCoursesSearchedValue}
               setSearchedValue={setAllCoursesSearchedValue}
               onAddCourse={onAddCourseToTeam}
               selectAll={selectAll}
               setSelectAll={setSelectAll}
               coursesLoading={isCoursesLoading}
            />
         }

      </div>
   );
};

export default Subheader;
