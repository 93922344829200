import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ModalLayout from '../../../layouts/ModalLayout/ModalLayout';
import searchIcon from '../../../assets/images/symbols/Search.svg';
import avatarPlaceholder from '../../../assets/images/avatar.svg';
import notFound from '../../../assets/images/symbols/nothing_found.svg';
import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';
import Dropdown from '../../Dropdown/Dropdown';
import { DEPARTMENTS, POSITIONS } from '../../../constants/departmentsAndPositions';
import { ACCESS_TABS, STUDENTS_TAB, TEAM_TAB } from '../../../constants/courses';
import { FixedSizeList as List } from 'react-window';

import cn from 'classnames';
import { transformRoleText } from '../../../utils/rolesHelper';
import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from '../../../constants/user';
import ChooseAdminAsStudentModal from '../ChooseAdminAsStudentModal/ChooseAdminAsStudentModal';
import useHandleModal from '../../../hooks/dom/useHandleModal';
import { useSelector } from 'react-redux';
import TabButton from '../../TabButton/TabButton';
import StepIndicator from '../../StepIndicator/StepIndicator';
import useUserRole from '../../../hooks/useUserRole';
import Loader from '../../Loader/Loader';
import useFindTranslations from '../../../hooks/useFindTranlsations';
import EmailsInput from '../../EmailsInput/EmailsInput';

const SelectUsersAndTeamsModal = ({
  title,
  isAddAccess,
  isOpen,
  onClose,
  onConfirm,
  setSelectedUsers,
  selectedUsers,
  usersList,
  setSelectedTeamList,
  selectedTeamList,
  teamList,
  lastUserRef,
  searchedValue,
  setSearchedValue,
  isUsersLoading,
  selectAllUsers,
  setSelectAllUsers,
  selectAllTeams,
  setSelectAllTeams,
  lastTeamRef,
  teamSearchedValue,
  setTeamSearchedValue,
  teamsLoading,
  changeAdminAsStudentStatus,
  isCourseAccessPage,
  emails,
  setEmails,
  progress,
  onCancelRequests
}) => {
  const [search, setSearch] = useState('');
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [clickedId, setClickedId] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedMode, setSelectedMode] = useState(STUDENTS_TAB);
  
  const currentCompanyId = useSelector(state => state.sidebar.companyId);
  const currentCompanyName = useSelector(state => state.sidebar.companyName);
  const { userRoleId } = useUserRole(currentCompanyId)

  const t = useFindTranslations();

  const confirmModal = useHandleModal();

const toggleSelectAllUsers = () => {
   setSelectAllUsers(!selectAllUsers);
   if(selectAllUsers) setSelectedUsers([])
};

useEffect(()=> {
   if(isUsersLoading) return;

   if (selectAllUsers) {
      setSelectedUsers(usersList);
   } else {
      setSelectedUsers(selectedUsers);
   }
}, [selectAllUsers, isUsersLoading])

  const toggleSelectAllTeams = () => {
   setSelectAllTeams(!selectAllTeams);
   if(selectAllTeams) setSelectedTeamList([]);
};

const changeStatus = (asWho) => {
   confirmModal.close();
   changeAdminAsStudentStatus(clickedId, asWho);

   const userToMark = usersList?.find((item) => item?.id === clickedId);

   // Check if the user is not already in selectedUsers before adding
   if (userToMark && !selectedUsers.some((user) => user.id === clickedId)) {
      setSelectedUsers([...selectedUsers, userToMark]);
   }
 };

useEffect(()=> {
   if(teamsLoading) return;

   if (selectAllTeams) {
      setSelectedTeamList(teamList);
   } else {
      setSelectedTeamList(selectedUsers);
   }
}, [selectAllTeams, teamsLoading])
  
  const onSelectDepartment = (department) => {
    setSelectedDepartment(department.name);
    setSelectedUsers((prev) => {
      const newUsersToAdd = usersList?.filter(
        (user) =>
          user.department === department.name && !prev.some((prevUser) => prevUser.id === user.id)
      );
      return [...prev, ...newUsersToAdd];
    });
  };

  const onSelectPosition = (position) => {
    setSelectedPosition(position.name);
    setSelectedUsers((prev) => {
      const newUsersToAdd = usersList?.filter(
        (user) => user.position === position.name && !prev.some((prevUser) => prevUser.id === user.id)
      );
      return [...prev, ...newUsersToAdd];
    });
  };

  const initDepartmentsAndPositionsList = () => {
    const departments = [];
    const positions = [];

    usersList?.forEach((user) => {
      if (user.department && !departments.some((department) => department.name === user.department)) {
        departments.push({ id: user.id, name: user.department });
      }
      if (user.position && !positions.some((position) => position.name === user.position)) {
        positions.push({ id: user.id, name: user.position });
      }
    });

    setDepartments(departments);
    setPositions(positions);
  };

  useEffect(() => {
    initDepartmentsAndPositionsList();
  }, [usersList]);

  const onSelectFilters = (user) => {
    if (selectedUsers?.find((item) => item?.id === user?.id))
      setSelectedUsers(selectedUsers?.filter((item) => item?.id !== user?.id));
    else setSelectedUsers([...selectedUsers, user]);
  };

  const onSelectTeamsFilters = (team) => {
    if (selectedTeamList?.find((item) => item?.id === team?.id))
      setSelectedTeamList(selectedTeamList?.filter((item) => item?.id !== team?.id));
    else setSelectedTeamList([...selectedTeamList, team]);
  };

  const filterBySearch = (list) => {
    const searchLower = search.toLowerCase().trim();

    return list?.filter((item) => {
      const firstName = item?.firstName?.toLowerCase()?.trim();
      const lastName = item?.lastName?.toLowerCase()?.trim();
      const email = item?.email?.toLowerCase()?.trim();
      const name = item?.name?.toLowerCase()?.trim();

      return (
        (firstName && firstName?.includes(searchLower)) ||
        (lastName && lastName?.includes(searchLower)) ||
        (email && email?.includes(searchLower)) ||
        (name && name?.includes(searchLower))
      );
    });
  };

  const filterByTeamSearch = (list) => {
    const searchLower = search.toLowerCase();

    return list?.filter((item) => {
      const name = item?.name?.toLowerCase();

      return name && name.includes(searchLower);
    });
  };

  const closeModal = () => {
    if(onCancelRequests) onCancelRequests();

    onClose();
    setSelectAllUsers(false);
    setSearch('');
    if(setSearchedValue) setSearchedValue('')
    setSelectedDepartment('');
    setSelectedPosition('');
    setSelectedUsers([]);
    setSelectedTeamList([]);
    setSelectAllTeams(false);
  };

  if(selectedMode === TEAM_TAB) {
   return (
      <ModalLayout 
         isOpen={isOpen} 
         onClose={closeModal}
         maxHeight={'670px'}
         maxWidth={'450px'}
         withCross
         customPadding={"33px 40px"}
         noBlur
      >
      <p className={styles.title}>
         {title ? title : 
             `${t?.select} ${selectedMode === STUDENTS_TAB ? t?.returnTranslation('Users') : t?.returnTranslation('Teams')}`
         }
      </p>

      <div className={styles.tabs}>
         {ACCESS_TABS?.map((item, key) =>
           <TabButton 
               item={item} 
               key={key} 
               mode={selectedMode} 
               setMode={setSelectedMode}
            />)}
      </div>

      <p className={styles.selected}>{t?.selected}: <span>{selectedUsers?.length + selectedTeamList?.length}</span></p>
      <div className={styles.search}>
        <img src={searchIcon} alt={''} />
        <input placeholder={t?.search} value={teamSearchedValue} onChange={(event) => setTeamSearchedValue(event?.target?.value)} />
      </div>

      {teamList?.length > 0 && 
         <div className={styles.selectAllButton} onClick={toggleSelectAllTeams}>
            <Checkbox isChecked={selectAllTeams} isGrey />
            <span>{t?.selectAll}</span>
         </div>
      }

      <div className={'default_loader_wrapper'}>
         {isUsersLoading && <Loader size={"small"} />}
      </div>   

      {!!teamList?.length ? (
        <div className={cn(styles.list, styles.team_list)}>
          {teamList?.map((item, key) => (
            <div ref={key === teamList?.length - 1 ? lastTeamRef : null} key={key} onClick={() => onSelectTeamsFilters(item)} className={cn(styles.list_item, styles.team_item)}>
              <Checkbox isChecked={selectedTeamList?.some((sItem) => sItem?.id === item?.id)} isGrey />
              {item?.name}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.notFound}>
          <img src={notFound} alt={''} />
          {selectedMode === STUDENTS_TAB ? t?.thereAreNoUsers : t?.thereAreNoData}
        </div>
      )}

       <div className={styles.btns}>
         <Button
               isBlack
               title={t?.cancel}
               onClick={closeModal}
            />

            <Button
               disabled={selectedTeamList.length === 0 && selectedTeamList.length || isUsersLoading}
               title={isAddAccess 
                     ?  
                  t?.create 
                     : 
                  `${progress ? `${t?.returnTranslation("Loading")}... ${progress}%` : `${t?.returnTranslation('Confirm')}`}`
               }
               onClick={onConfirm ? () => {
                  onConfirm().then(()=> {
                     closeModal();
                  });
               } : onClose}
            />
      </div>
      {isAddAccess && 
         <StepIndicator step={2} />
      }
    </ModalLayout>
   )
  }


 const UserItem = ({ index, style, data }) => {
   const item = usersList[index];
 
   return (
     <div ref={index === usersList?.length - 1 ? lastUserRef : null} style={style} className={styles.flexed} key={index}>
       <div onClick={() => onSelectFilters(item)} className={styles.list_item}>
         <Checkbox isChecked={selectedUsers?.some((sItem) => sItem?.id === item?.id)} isGrey />
         <img src={`${item.avatar ? item.avatar : avatarPlaceholder}`} alt="" />
         <div className={styles.user_info}>
           <span>
             {!item?.firstName && !item?.lastName && !item?.email && '-'}
             {item?.firstName} {item?.lastName}
           </span>
           <div className={styles.role}>
             {transformRoleText(item?.userBranchRoles?.[0]?.role?.name || "-", currentCompanyName)}
           </div>
         </div>
         {item?.userBranchRoles[0]?.role?.id !== ROLE_ID_STUDENT &&
           item?.userBranchRoles[0]?.role?.id !== ROLE_ID_SUPER_ADMIN &&
           isCourseAccessPage && (
             <span
               onClick={() => {
                 confirmModal.open();
                 setClickedId(item?.id);
               }}
               className={cn(styles.switch, item?.isAsStudent ? styles.marked_as_student : "")}
             >
               {item?.isAsStudent ? t?.markedAsStudent : t?.addAsStudent}
             </span>
           )}
       </div>
     </div>
   );
 };
 
  return (
    <ModalLayout 
      isOpen={isOpen} 
      onClose={closeModal} 
      maxHeight={selectedMode === STUDENTS_TAB ? '690px' : '650px'}
      maxWidth={'520px'} 
      withCross 
      customPadding={"33px 40px"}
      noBlur
    >
      <p className={styles.title}>
      {title ? title : 
             `${selectedMode === STUDENTS_TAB ? t?.users : t?.teams}`
         }
      </p>

      <div className={styles.tabs}>
            {ACCESS_TABS?.map((item, key) =>
               <TabButton 
                  item={item} 
                  key={key} 
                  mode={selectedMode} 
                  setMode={setSelectedMode}
               />
            )}
      </div>

      <p className={styles.selected}>{t?.selected}: <span>{selectedUsers?.length + selectedTeamList?.length}</span></p>
      <div className={styles.search}>
        <img src={searchIcon} alt={''} />
        <input placeholder={t?.search} value={searchedValue} onChange={(event) => setSearchedValue(event?.target?.value)} />
      </div>

      <div className={styles.dropdowns}>
        <div className={styles.dropdown}>
          <Dropdown
            data={departments}
            field={'name'}
            value={selectedDepartment}
            placeholder={t?.selectByDepartment}
            onChange={onSelectDepartment}
            maxHeight={'220px'}
            searchNeeded
          />
        </div>
        <div className={styles.dropdown}>
          <Dropdown
            data={positions}
            maxHeight={'180px'}
            field={'name'}
            value={selectedPosition}
            placeholder={t?.selectByPosition}
            onChange={onSelectPosition}
            searchNeeded
          />
        </div>
      </div>

      {isCourseAccessPage && 
         <EmailsInput
            placeholder={t?.returnTranslation("Search by emails")} 
            setEmails={setEmails}
         />
      }

      <div className={'default_loader_wrapper'}>
         {isUsersLoading && <Loader size={"small"} />}
      </div>


      {usersList?.length > 0 && 
         <div className={styles.selectAllButton} onClick={toggleSelectAllUsers}>
            <Checkbox isChecked={selectAllUsers} isGrey />
            <span>{t?.returnTranslation("Select all")}</span>
         </div>
      }

      {!!usersList?.length ? (
         <List
            height={!isCourseAccessPage ? 240 : 195} 
            itemCount={usersList.length}
            itemSize={60} 
            width="100%"
         > 
              {({ index, style, data }) => (
                  <UserItem
                     index={index}  
                     style={style}  
                     data={data}    
                  />
               )}
         </List>
      ) : (
         <div className={styles.notFound}>
            <img src={notFound} alt={''} />
            {selectedMode === STUDENTS_TAB ? t?.thereAreNoUsers : t?.thereAreNoData}
         </div>
      )}

       <div className={styles.btns}>
         <Button
            isBlack
            title={t?.cancel}
            onClick={closeModal}
         />
         <Button
            disabled={selectedTeamList.length === 0 && selectedTeamList.length || isUsersLoading}
            title={isAddAccess 
                     ?  
                  t?.create 
                     : 
                  `${progress ? `${t?.returnTranslation("Loading")}... ${progress}%` : `${t?.returnTranslation('Confirm')}`}`
               }
            onClick={onConfirm ? () => {
               onConfirm().then(()=> {
                  closeModal();
               });
            } : onClose}
         />
       </div>
          {isAddAccess && 
            <StepIndicator step={2} />
          }

         <ChooseAdminAsStudentModal
            onAdmin={() => changeStatus("admin")}
            onStudent={() => changeStatus("student")}
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            title={t?.returnTranslation("Adding an admin")}
            subtitle={t?.returnTranslation("You can add an admin as student or as admin")}
         />
     
    </ModalLayout>
  );
};

export default SelectUsersAndTeamsModal;
