import React, { useEffect, useRef, useState } from "react";
import lessonImg from "../../../../assets/images/symbols/lesson.svg";
import ModuleApi from "../../../../utils/api/ModuleApi";
import LessonApi from "../../../../utils/api/LessonApi";
import Input from "../../../../components/Input/Input";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import Loader from "../../../../components/Loader/Loader";
import ModuleItem from "../ModuleItem/ModuleItem";
import useOutsideClick from "../../../../hooks/dom/useOutsideClick";
import CourseApi from "../../../../utils/api/CourseApi";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getRandomLetter } from "../../../../utils/coursesHelper";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import icon_hide_show_menu from "../../../../assets/images/icon_hide_show_menu.svg";

import cn from "classnames";
import styles from "./styles.module.scss";
import { LESSON_TYPE_WITH_TESTING_NUMBER } from "../../../../constants/courses";
import { TEST_STATUS_APPROVED } from "../../../../constants/tests";
import { COURSE_LANGUAGES_LIST } from "../../../../constants/languages";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

const SubmenuBlock = ({
   onClickModule,
   activeModule,
   onClickElement,
   activeElement,
   course,
   modulesWithCount,
   questionsDataFromInputs,
   fetchData,
   loading,
   modules,
   questions,
   setModules,
   fetchCourse,
   isLessonsSidebarOpen,
   isTestStarted,
   userId,
   toggleLessonsSidebar,
   setOpenedModulesIds,
   openedModulesIds,
   activeLessonRef,
   userStatusOnCoursePaused,
   changeCourseLanguage,
   courseLanguage,
   isFewLangs
}) => {
   const dropdownRef = useRef();
   const dotsRef = useRef();

   const params = useParams();

   const t = useFindTranslationsByString();

   const isCheckLangs = isFewLangs === 'true' ? (courseLanguage?.id ? true : false) : true

   useEffect(() => {
      if(userId && isCheckLangs) {
         fetchData();
      }
   }, [userId]); 

   const initialAvailableLanguages = COURSE_LANGUAGES_LIST?.filter(lang => 
      course?.availableLanguages?.includes(lang?.code)
   );

   const defaultLang = COURSE_LANGUAGES_LIST?.find(lang => 
      course?.defaultLanguage === lang?.code
   );
   
   const isDefaultLangInAvailableLanguages = course?.availableLanguages?.some(item => item === defaultLang?.code);
 
   const availableLanguages = isDefaultLangInAvailableLanguages
         ? initialAvailableLanguages
         : [defaultLang, ...initialAvailableLanguages]; 

   return (
      <div
         className={cn(
            styles.submenuBlock,
            !isLessonsSidebarOpen ? styles.closed_sidebar : "",
            isTestStarted ? styles.hide : "",
         )}
      >
         <div className={styles.header}>
            {availableLanguages?.length > 1 &&
              <div className={styles.languages}>
                  {availableLanguages?.map(lang => 
                     <button onClick={()=> changeCourseLanguage(lang)} className={cn(styles.lang_btn, courseLanguage?.code === lang?.code ? styles.active_lang : '')} key={lang?.code}> {lang?.displayed_code} </button>
                  )}
               </div>
            }
       
            <div className={styles.header_subtitle}>{t("Course name")}:</div>
            <div className={styles.course_name}>
               <CustomTooltip
                  limit={70}
                  text={course?.name}
                  maxWidth={'280px'}
                  position="bottom"
                  id={"course_name"}
               />
            </div>
         </div>

         <button onClick={toggleLessonsSidebar} className={cn(
            styles.toggle_sidebar, 
         )}>
            <img className={!isLessonsSidebarOpen ? styles.show_menu : ''} src={icon_hide_show_menu} alt="" /> 
         </button>

         <div className={styles.list}>
            <div className={styles.width_full}>
               {modules?.map((module, index) => (
                  <ModuleItem
                     userStatusOnCoursePaused={userStatusOnCoursePaused}
                     activeLessonRef={activeLessonRef}
                     modules={modules}
                     questions={questions}
                     key={module.id}
                     module={module}
                     index={index}
                     onClickModule={onClickModule}
                     onClickElement={onClickElement}
                     activeModule={activeModule}
                     activeElement={activeElement}
                     fetchData={fetchData}
                     needOpenModuleByDefault={module?.id === activeModule}
                     questionsDataFromInputs={questionsDataFromInputs}
                     isLinear={course?.isLinear ? true : false}
                     setOpenedModulesIds={setOpenedModulesIds}
                     openedModulesIds={openedModulesIds}
                     isModuleCompleted = {
                        module?.lessons?.every((lesson, lessonIdx) => {
                            if (lesson.userTests && lesson.userTests.length > 0) {
                                const firstTest = lesson.userTests[0];
                                return firstTest.status === TEST_STATUS_APPROVED;
                            } else {
                                return lesson.completed;
                            }
                        }) && (module?.lessons?.length > 0) 
                    }
                  />
               ))}
               {modules?.length === 0 && !loading && <span className={styles.no_access}>{t("There is no content yet or you do not have access to course materials")}.</span>}
            </div>
         </div>
      </div>
   );
};

export default React.memo(SubmenuBlock);
